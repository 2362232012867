import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const max3dpro = createSlice({
    name: 'max3dpro',
    initialState,
    reducers: {
        ticketMax3dpro:   (state, action) => (action.payload)
    }
});

export const { ticketMax3dpro } = max3dpro.actions;

export default max3dpro.reducer;