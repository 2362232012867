import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const max3d = createSlice({
    name: 'max3d',
    initialState,
    reducers: {
        ticketMax3d:   (state, action) => (action.payload)
    }
});

export const { ticketMax3d } = max3d.actions;

export default max3d.reducer;