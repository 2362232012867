import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

import Header from '../components/Lode/Header';
import Footer from '../components/Footer';

import {updateTicketDe , updateTicketLo} from '../web3/testnet';

function TicketLode() {
    const DeState = useSelector((store) => store.de);
    const LoState = useSelector((store) => store.lo);
    // const [de, setDe] = useState([]);
    useEffect(() => {
        updateTicketDe(process.env.REACT_APP_LODE);
        updateTicketLo(process.env.REACT_APP_LODE);
        return () => {
        };
    }, []);
    // console.log(DeState);

    function ticketResult (ticket) {
        if (!ticket.rewarded) return <span className='color-orange'>Pending</span>
        else if (ticket.win_multiplier == 0)
            return <span className='color-red'>Loss</span>
        else return <span className='color-green'>{ticket.win_amount}</span>
    } 
    
    return (
        <div className="PageWraper myticletPageWrap lotteryBtn pt-5">
            <Header />
            <div className="tablet-btn text-center">
                <a className="BlueBtn affiliaeBtn"><img src="/static/media/icons/Affilate.svg" alt="Walet" /> 1520</a>
            </div>
            <div className="container">
                <div className="userBettingList">
                    <div className="bettingList">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Type : Number </th>
                                    <th>Price</th>
                                    <th>Time Generator</th>
                                    <th>Result</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DeState.map(de => {
                                    return <tr key={v4()}>
                                        <td>DE : {de.bet_point}</td>
                                        <td><span className='color-blue'>{de.accept_amount}</span></td>
                                        <td>{de.bet_at}</td>
                                        <td>
                                            {ticketResult(de)}
                                        </td>
                                    </tr>
                                })}
                                {LoState.map(lo => {
                                    return <tr key={v4()}>
                                        <td>LO : {lo.bet_point}</td>
                                        <td><span className='color-blue'>{lo.accept_amount}</span></td>
                                        <td>{lo.bet_at}</td>
                                        <td>
                                            {ticketResult(lo)}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default TicketLode;

/*
console.log("ticket.game_ended_at: ", ticket.game_ended_at);
console.log("currentDate: ", currentDate);
console.log("Date(ticket.game_ended_at): ", new Date(ticket.game_ended_at));
console.log("ticket.game_ended_at: ", ticket.game_ended_at);
console.log("compare: ", endGame.getTime() < currentDate.getTime(), endGame.getTime() > currentDate.getTime());
console.log("check 1: ", endGame.getTime() < currentDate.getTime());
console.log("check 2: ", endGame.getTime() > currentDate.getTime());
*/