import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const de = createSlice({
    name: 'de',
    initialState,
    reducers: {
        ticketDe:   (state, action) => (action.payload)
    }
});

export const { ticketDe } = de.actions;

export default de.reducer;