import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';


import Header from '../components/Max/Header';
import Footer from '../components/Footer';


import {updateTicketMax3d , updateTicketMax3dPro} from '../web3/testnet';

function TicketMax() {
    const Max3dState = useSelector((store) => store.max3d);
    const Max3dproState = useSelector((store) => store.max3dpro);

    useEffect(() => {
        updateTicketMax3d(process.env.REACT_APP_MAX);
        updateTicketMax3dPro(process.env.REACT_APP_MAX);
        // console.log("TicketMax");
        return () => {
        };
    }, []);

    function ticketResult (ticket) {
        if (!ticket.rewarded) return <span className='color-orange'>Pending</span>
        else if (ticket.win_multiplier == 0)
            return <span className='color-red'>Loss</span>
        else return <span className='color-green'>{ticket.win_amount}</span>
    } 

    return (
        <div className="PageWraper myticletPageWrap lotteryBtn pt-5">
            <Header />
            <div className="tablet-btn text-center">
                <a className="BlueBtn affiliaeBtn"><img src="/static/media/icons/Affilate.svg" alt="Walet" /> 1520</a>
            </div>
            <div className="container">
                <div className="userBettingList">
                    <div className="bettingList">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Type : Number</th>
                                    <th>Price</th>
                                    <th>Time Generator</th>
                                    <th>Result</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Max3dState.map(max3d => {
                                    return <tr key={v4()}>
                                        <td>MAX 3D : {max3d.bet_point[0]} - {max3d.bet_point[1]}</td>
                                        <td><span className='color-blue'>{max3d.accept_amount}</span></td>
                                        <td>{max3d.bet_at}</td>
                                        <td>
                                            {ticketResult(max3d)}
                                        </td>
                                    </tr>
                                })}
                                {Max3dproState.map(max3dpro => {
                                    return <tr key={v4()}>
                                        <td>MAX 3D PRO : {max3dpro.bet_point[0]} - {max3dpro.bet_point[1]}</td>
                                        <td><span className='color-blue'>{max3dpro.accept_amount}</span></td>
                                        <td>{max3dpro.bet_at}</td>
                                        <td>
                                            {ticketResult(max3dpro)}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default TicketMax;