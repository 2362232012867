import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const lott6x45 = createSlice({
    name: 'lott6x45',
    initialState,
    reducers: {
        ticketLott6x45:   (state, action) => (action.payload)
    }
});

export const { ticketLott6x45 } = lott6x45.actions;

export default lott6x45.reducer;