import React from 'react';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {storeAppDispatch} from '../../GlobalState/Store';
import { userAccount , updateBankroll} from '../../web3/testnet';
import { setBankroll } from '../../GlobalState/GameReducer';

import { Link, useNavigate } from 'react-router-dom';
import {socket} from '../../services/max3d';


import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import HomeLineWhite from '../../assets/img/icons/HomeLineWhite.svg';
import Affilate from '../../assets/img/icons/affilate-blue.svg';
import AffilateW from '../../assets/img/icons/Affilate.svg';
import ArrowLeft from '../../assets/img/icons/arrow-left-white.svg';


function Header() {

    const navigate = useNavigate();
    const GameState = useSelector((store) => store.game);
    
    // if (!GameState.bankroll) updateBankroll(process.env.REACT_APP_MAX);
    if (!GameState.bankroll || GameState.sc != process.env.REACT_APP_MAX) updateBankroll(process.env.REACT_APP_MAX);
    // console.log ("check: ", GameState.bankroll, GameState.sc, !GameState.bankroll, GameState.sc != process.env.REACT_APP_MAX);

    useEffect(() => {

        socket.on('BANKROLL', (...args) => {
            storeAppDispatch(setBankroll(args[0].bankroll));
            console.log("...args", ...args);
            console.log(args[0].bankroll);
        });
  
        return () => {
            socket.off('BANKROLL');
        };
    }, []);

    return ( 
        <header className="header_area">
            <div className="header_container">
                <Navbar expand="lg">
                    <div onClick={() => navigate(-1)} className="bigsmall_heade_left">
                        <a className="backLeft arrowBtn"><img src={ArrowLeft} alt="ArrowLeft" /></a>
                        <h2 className='menuPageTitme'>MAX 3D</h2>
                    </div>
                    <Navbar.Collapse id="navbarCollapse">
                        <Nav className="mainNavBar">
                            <Link to="/ketqua-max" className='nav-link sportMenu resultHisLink'>Result History</Link>
                            <Link to="/ticket-max" className='nav-link sportMenu myTicketsLink'>My Tickets</Link>
                        </Nav>
                    </Navbar.Collapse>
                    <a onClick={() => navigate("/bankroll/baccarat")} className='walletLink grayBtn mr-1' >
                        <img src={HomeLineWhite} alt="HomeLineWhite" />
                        <span><img src={Affilate} alt="Affilate" /> {GameState.bankroll ? parseFloat(GameState.bankroll).toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}</span>
                    </a>
                    <span className='tablet-none lottryPageBtnNone'> 
                    {userAccount ? <a onClick={() => navigate("/my-wallet")} className="BlueBtn table-none">{userAccount}</a> : <a onClick={() => navigate("/login")}  className="BlueBtn">Connect to wallet</a> }
                    </span>
                    <div className="lottryPageBtn tablet-none">
                        <a onClick={() => navigate("/bankroll/baccarat")} className='affiliaeBtn BlueBtn table-none mr-1' >
                            <img src={AffilateW} alt="Affilate" />  {GameState.bankroll ? parseFloat(GameState.bankroll).toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}
                        </a>
                    </div>
                    <Navbar.Toggle aria-controls="navbarCollapse" />
                </Navbar>
            </div>
        </header>
     );
}

export default Header;
