import React from 'react';
import { useState, useEffect } from 'react';

import Header from '../components/Lode/Header';
import Footer from '../components/Footer';
import UserBettingList from '../components/Lode/UserBettingList';
import {userAccount, sendTransaction} from '../web3/testnet';

function amountControl (value) {
    // the first need to floor the number to make sure when we Float.toFixed it, the number is not bigger than balance.
    let temp = value * Math.pow(10, process.env.REACT_APP_TOKENPRECISION);
    temp = Math.floor(temp);
    temp = temp / Math.pow(10, process.env.REACT_APP_TOKENPRECISION);
    
    // now return the quantity in correct format of token precision and symbol.
    return Number.parseFloat(temp).toFixed(process.env.REACT_APP_TOKENPRECISION) + ' ' + process.env.REACT_APP_TOKENSYMBOL;
  }


function Lode() {
    const [number, setNumber] = useState(0);
    const [amount, setAmount] = useState(1);

    function handleNumber(e) {
        // e.preventDefault();
        if (e.target.value.match(/^[0-9]+$/)) 
        setNumber(e.target.value);
    }

    function handleAmount(e) {
        // e.preventDefault();
        if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) 
        setAmount(e.target.value);
    }
    function amountFloat(){
        // The conditional prevents parseFloat(null) = NaN (when the user deletes the input)
        setAmount(parseFloat(amount) || 1);
    }

    const [activeButton, setActiveButton] = useState(null);

    /// buttonId: 1 -> DE; 2 -> LO
    const handleClick = (buttonId) => {
      setActiveButton(buttonId === activeButton ? null : buttonId);
    };

    function buyTicket() {
        let type;
        if (activeButton === 1 )    type = "de";
        if (activeButton === 2 )    type = "lo";
        
        const transact = {
            actions: [{
                account: process.env.REACT_APP_TOKENCONTRACT, 
                name: 'transfer',
                authorization: [{
                actor: userAccount,
                permission: 'active',
                }],
                data: {
                from: userAccount,
                to: process.env.REACT_APP_LODE,
                quantity:  amountControl(amount),
                memo: type + ';' + number,
                },
            }]
        };
        console.log(transact);
        sendTransaction(transact);
    }

    return ( 
        <div className="PageWraper LotterypageWrap lotteryBtn pt-5">
            <Header/>
            <div className="tablet-btn text-center">
                <a className="BlueBtn">Connect to wallet</a>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="LotterypageNumber blueCard">
                            <div className="timeCountDown">
                                <p>8<span>h</span></p>:<p>15<span>m</span></p>:<p>30<span>s</span></p>
                            </div>
                            <div className="selectNumberLott">
                                <h2>{number}</h2>
                                <p>Selected number for the lottery</p>
                            </div>
                            <div className="numberRange">
                                <div className="rangeInput">
                                    <input type="range" id="" name="" min="0" max="9" onChange={(handleNumber)} defaultValue={number}/>
                                </div>
                                <div className="rangeNumber">
                                    <p>00</p>
                                    <p>99</p>
                                </div>
                            </div>
                        </div>
                        <div className="lotteryBeat">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="beat_amounts">
                                        <div className="amoiunt_to_beat">
                                            Bet Amount
                                            {/* <button type='button'>100 DIME</button> */}
                                            <div className="theAmount"><input type="number" onChange={handleAmount} onBlur ={amountFloat}  value={amount} />DIME</div>
                                        </div>
                                        <div className="numberRange">
                                            <input type="range" min="1" max="100000" onChange={ e => {
                                                    if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) 
                                                    setAmount(e.target.value); 
                                                }} 
                                            value={amount} />
                                            <div className="rangeNumber">
                                                <p>1</p>
                                                <p>{amount > 100000 ? amount : '100K'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="buyTickets">
                                        <div className="dualButton">                  
                                            <button
                                                className={activeButton === 1 ? 'active' : ''}
                                                onClick={() => handleClick(1)}
                                            >
                                                DE
                                            </button>                      
                                            <button
                                                className={activeButton === 2 ? 'active' : ''}
                                                onClick={() => handleClick(2)}
                                            >
                                                LO
                                            </button>
                                        </div>
                                        <button onClick={() => buyTicket()} type='button' className="buyTicketsBtn">
                                            Buy Ticket
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <UserBettingList/>
                    </div>
                </div>
                <div className="how_to_playBox">
                    <h2>How to Play : </h2>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="howToPlayCard">
                                <h3>Buy Tickets :</h3>
                                <p>Buy a ticket and Choose numbers for the ticket.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="howToPlayCard">
                                <h3>Wait for the Draw :</h3>
                                <p>Wait for the draw at 15:00 UTC+0 every day.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="howToPlayCard">
                                <h3>Check the Prizes :</h3>
                                <p>Once the draw is over, Come back to this page and check your prize.</p>
                            </div>
                        </div>
                    </div>
                    <p className='color-blue'>For each draw you have to select the number for betting, then wait for the draw and get chance to win the prize. </p>
                    <h5 className='color-yellow'>1 win 99x for DE <span className='color-blue'>and</span> 27 win 99x (1 win 99/27) for LO</h5>
                </div>
            </div>
            <Footer/>
        </div>
     );
}

export default Lode;