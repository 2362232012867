import { combineReducers } from 'redux';

import user from './UserReducer';
import game from './GameReducer';
import hilo from './HiLoReducer';
import de from './DeReducer';
import lo from './LoReducer';
import max3d from './Max3dReducer';
import max3dpro from './Max3dProReducer';
import result from './ResultReducer';
import lott6x45 from './Lott6x45Reducer';

export const rootReducer = combineReducers({
    user,
    game,
    hilo,
    de,
    lo,
    max3d,
    max3dpro,
    lott6x45,
    result
});
