import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import HomeLineWhite from '../../assets/img/icons/HomeLineWhite.svg';
import Affilate from '../../assets/img/icons/affilate-blue.svg';
import AffilateW from '../../assets/img/icons/Affilate.svg';
import ArrowLeft from '../../assets/img/icons/arrow-left-white.svg';

import {userAccount} from '../../web3/testnet';

function Header() {
    const navigate = useNavigate();
    return ( 
        <header className="header_area">
            <div className="header_container">
                <Navbar expand="lg">
                    <div onClick={() => navigate(-1)} className="bigsmall_heade_left">
                        <a className="backLeft arrowBtn"><img src={ArrowLeft} alt="ArrowLeft" /></a>
                        <h2 className='menuPageTitme'>Lot 6x45</h2>
                    </div>
                    <Navbar.Collapse id="navbarCollapse">
                        <Nav className="mainNavBar">
                            <Link to="/ketqua-6x45" className='nav-link sportMenu resultHisLink'>Result History</Link>
                            <Link to="/ticket-6x45" className='nav-link sportMenu myTicketsLink'>My Tickets</Link>
                        </Nav>
                    </Navbar.Collapse>
                    <span className='tablet-none lottryPageBtnNone'> 
                    {userAccount ? <a onClick={() => navigate("/my-wallet")} className="BlueBtn table-none">{userAccount}</a> : <a onClick={() => navigate("/login")}  className="BlueBtn">Connect to wallet</a> }
                    </span>
                    <div className="lottryPageBtn tablet-none">
                        <a onClick={() => navigate("/bankroll/baccarat")} className='affiliaeBtn BlueBtn table-none mr-1' >
                            <img src={AffilateW} alt="Affilate" /> 1520
                        </a>
                    </div>
                    <Navbar.Toggle aria-controls="navbarCollapse" />
                </Navbar>
            </div>
        </header>
     );
}

export default Header;
