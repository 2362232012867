import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

import Header from '../components/Lot6x45/Header';
import Footer from '../components/Footer';

import { updateTicketLott6x45 } from '../web3/testnet';

function Ticket6x45() {

    const LottState = useSelector((store) => store.lott6x45);

    console.log(LottState);

    useEffect(() => {
        updateTicketLott6x45(process.env.REACT_APP_LOT);
        return () => {
        };
    }, []);

    function ticketResult (ticket) {
        if (!ticket.rewarded) return <span className='color-orange'>Pending</span>
        else if (ticket.win_multiplier == 0)
            return <span className='color-red'>Loss</span>
        else return <span className='color-green'>{ticket.win_amount}</span>
    } 

    return (
        <div className="PageWraper myticletPageWrap lotteryBtn pt-5">
            <Header />
            <div className="tablet-btn text-center">
                <a className="BlueBtn affiliaeBtn"><img src="/static/media/icons/Affilate.svg" alt="Walet" /> 1520</a>
            </div>
            <div className="container">
                <div className="userBettingList">
                    <div className="bettingList">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Number</th>
                                    <th>Time Generator</th>
                                    <th>Result</th>
                                </tr>
                            </thead>
                            <tbody>
                                {LottState.map(ticket => {
                                    return <tr key={v4()}>
                                        <td>{ticket.bet_point[0]} - {ticket.bet_point[1]} - {ticket.bet_point[2]} - {ticket.bet_point[3]} - {ticket.bet_point[4]} - {ticket.bet_point[5]}</td>
                                        <td>{ticket.bet_at}</td>
                                        <td>
                                            {ticketResult(ticket)}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Ticket6x45;