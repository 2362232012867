import React from 'react';

function UserBettingList() {
    return ( 
        <div className="userBettingList">
            <h2>User Betting List</h2>
            <div className="bettingList">
                <table className="table">
                    <tbody>
                        <tr>
                            <td>Username0001</td>
                            <td>Big</td>
                            <td><span className='color-blue'>100.123456789</span></td>
                        </tr>
                        <tr>
                            <td>Username0001</td>
                            <td>Big</td>
                            <td><span className='color-blue'>100.123456789</span></td>
                        </tr>
                        <tr>
                            <td>Username0001</td>
                            <td>Big</td>
                            <td><span className='color-blue'>100.123456789</span></td>
                        </tr>
                        <tr>
                            <td>Username0001</td>
                            <td>Big</td>
                            <td><span className='color-blue'>100.123456789</span></td>
                        </tr>
                        <tr>
                            <td>Username0001</td>
                            <td>Big</td>
                            <td><span className='color-blue'>100.123456789</span></td>
                        </tr>
                        <tr>
                            <td>Username0001</td>
                            <td>Big</td>
                            <td><span className='color-blue'>100.123456789</span></td>
                        </tr>
                        <tr>
                            <td>Username0001</td>
                            <td>Big</td>
                            <td><span className='color-blue'>100.123456789</span></td>
                        </tr>
                        <tr>
                            <td>Username0001</td>
                            <td>Big</td>
                            <td><span className='color-blue'>100.123456789</span></td>
                        </tr>
                        <tr>
                            <td>Username0001</td>
                            <td>Big</td>
                            <td><span className='color-blue'>100.123456789</span></td>
                        </tr>
                        <tr>
                            <td>Username0001</td>
                            <td>Big</td>
                            <td><span className='color-blue'>100.123456789</span></td>
                        </tr>
                        <tr>
                            <td>Username0001</td>
                            <td>Big</td>
                            <td><span className='color-blue'>100.123456789</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
     );
}

export default UserBettingList;