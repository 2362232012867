import React from 'react';
import { useState, useEffect } from 'react';
import { v4 } from 'uuid';

import {socket} from '../../services/lode';

function UserBettingList() {

    const [bets, setBet] = useState([]);

    useEffect(() => {
        socket.on('NEW_BET', (...args) => {
            // setBet([args[0], ...bets]);
            console.log("NEW_BET", ...args);
            setBet([args[0], ...bets]);
            console.log(bets);
        });
        socket.on('TOTAL_BET', (...args) => {
            // setBet([args[0], ...bets]);
            console.log("TOTAL_BET", ...args);
        });
        socket.on('RESULT', (...args) => {
            // setBet([args[0], ...bets]);
            console.log("RESULT", ...args);
        });
        socket.on('BANKROLL', (...args) => {
            // setBet([args[0], ...bets]);
            console.log("BANKROLL", ...args);
        });

        return () => {
            socket.off('NEW_BET');
            socket.off('TOTAL_BET');
            socket.off('RESULT');
            socket.off('BANKROLL');
        };
    });

    return ( 
        <div className="userBettingList mt-0">
            <h2>User Betting List</h2>
            <div className="bettingList">
                <table className="table">
                    <tbody>
                        {bets.map(bet => {
                            return <tr key={v4()}>
                                <td>{bet.wallet}</td>
                                <td>{bet.side} {bet.bet_point}</td>
                                <td><span className='color-blue'>{bet.accept_amount}</span></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
     );
}

export default UserBettingList;