import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

import Header from '../components/Lot6x45/Header';
import Footer from '../components/Footer';

import { resultLott } from '../web3/testnet';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('-');
}

function Kq6x45() {

    const ResultState = useSelector((store) => store.result);

    useEffect(() => {
        resultLott(process.env.REACT_APP_LOT);
        return () => {
        };
    }, []);

    if (ResultState.type === "lott")
    return ( 
        <div className="PageWraper resultPage ResultPageWrape3 lotteryBtn pt-5">
            <Header/>
            <div className="tablet-btn text-center">
                <a className="BlueBtn affiliaeBtn"><img src="/static/media/icons/Affilate.svg" alt="Walet" /> 1520</a>
            </div>
            <div className="container">
                <div className="resultContainer">
                    <div className="resultTop">
                        <p>Winning Tickets : <b className='color-yellow'>120</b></p>
                        <p>Winning Price :  <b className='color-yellow'>5000 DIME</b></p>
                    </div>    
                    {ResultState.data.map(result => {
                        return <div className="resultCol" key={v4()}>
                            <div className="selectNumberLottBtnsList">
                                <button type='button'>{result.result[0]}</button>
                                <button type='button'>{result.result[1]}</button>
                                <button type='button'>{result.result[2]}</button>
                                <button type='button'>{result.result[3]}</button>
                                <button type='button'>{result.result[4]}</button>
                                <button type='button'>{result.result[5]}</button>
                            </div>
                            <p className='color-blue'>{formatDate(result.ended_at)}</p>
                        </div>
                    })}
                </div> 
            </div>
            <Footer/>
        </div>
     );
    else return (<></>);
}

export default Kq6x45;