import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const lo = createSlice({
    name: 'lo',
    initialState,
    reducers: {
        ticketLo:   (state, action) => (action.payload)
    }
});

export const { ticketLo } = lo.actions;

export default lo.reducer;