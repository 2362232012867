import React from 'react';
import { useState, useEffect } from 'react';
import { v4 } from 'uuid';

import {socket} from '../../services/lot6x45';

function UserBettingList() {

    const [bets, setBet] = useState([]);

    useEffect(() => {
        socket.on('NEW_BET', (...args) => {
            setBet([args[0], ...bets]);
        });
        return () => {
            socket.off('NEW_BET');
        };
    });

    return ( 
        <div className="userBettingList userBetLott3 mt-0">
            <h2>User Betting List</h2>
            <div className="bettingList">
                <table className="table">
                    <tbody>
                        {bets.map(bet => {
                            return <tr key={v4()}>
                                <td>{bet.wallet}</td>
                                <td>{bet.bet_point[0]} - {bet.bet_point[1]} - {bet.bet_point[2]} - {bet.bet_point[3]} - {bet.bet_point[4]} - {bet.bet_point[5]}</td>
                                <td><span className='color-blue'>DRAW {bet.game_id}</span></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
     );
}

export default UserBettingList;

/*
TOTAL_BET 
{game_id: '27', bet_amount: '10.0000 DIME', jackpot: '12012.2500 DIME'}
bet_amount
: 
"10.0000 DIME"
game_id
: 
"27"
jackpot
: 
"12012.2500 DIME"
600000
*/