import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';


import Header from '../components/Max/Header';
import Footer from '../components/Footer';
import Star from '../assets/img/new/StarWhite.svg';

import { resultMax } from '../web3/testnet';

function KqMax() {

    const ResultState = useSelector((store) => store.result);

    useEffect(() => {
        resultMax(process.env.REACT_APP_MAX);
        return () => {
        };
    }, []);

    if (ResultState.type === "max")
    return ( 
        <div className="PageWraper resultPage ResultPageWrape2 lotteryBtn pt-5">
            <Header/>
            <div className="tablet-btn text-center">
                <a className="BlueBtn affiliaeBtn"><img src="/static/media/icons/Affilate.svg" alt="Walet" /> 1520</a>
            </div>
            <div className="container">
                <div className="resultContainer">
                    <div className="resultTop">
                        <p>Winning Tickets : <b className='color-yellow'>120</b></p>
                        <p>Winning Price :  <b className='color-yellow'>5000 DIME</b></p>
                    </div>
                    {ResultState.data.map(result => {
                        return (<>
                            <div className="resultCart" key={v4()}>
                                <div className="resultCartDate">{result.ended_at}</div>
                                <div className="resultColum">
                                    <div className="resultTitle">Star Number :</div>
                                    <div className="result_number startResult">
                                        <button type='button'><img src={Star} alt="Star" />{result.result1[[0]]}</button>
                                        <button type='button'><img src={Star} alt="Star" />{result.result1[[1]]}</button>
                                    </div>
                                    <div className="winingPrice">5000 DIME</div>
                                </div>
                                <div className="resultColum">
                                    <div className="resultTitle">First Class :</div>
                                    <div className="result_number">
                                        <button type='button'>{result.result2[[0]]}</button>
                                        <button type='button'>{result.result2[[1]]}</button>
                                        <button type='button'>{result.result2[[2]]}</button>
                                        <button type='button'>{result.result2[[3]]}</button>
                                    </div>
                                    <div className="winingPrice">2000 DIME</div>
                                </div>
                                <div className="resultColum">
                                    <div className="resultTitle">Second Class :</div>
                                    <div className="result_number">
                                        <button type='button'>{result.result3[[0]]}</button>
                                        <button type='button'>{result.result3[[1]]}</button>
                                        <button type='button'>{result.result3[[2]]}</button>
                                        <button type='button'>{result.result3[[3]]}</button>
                                        <button type='button'>{result.result3[[4]]}</button>
                                        <button type='button'>{result.result3[[5]]}</button>
                                    </div>
                                    <div className="winingPrice">1000 DIME</div>
                                </div>
                                <div className="resultColum">
                                    <div className="resultTitle">Third Class :</div>
                                    <div className="result_number">
                                        <button type='button'>{result.result4[[0]]}</button>
                                        <button type='button'>{result.result4[[1]]}</button>
                                        <button type='button'>{result.result4[[2]]}</button>
                                        <button type='button'>{result.result4[[3]]}</button>
                                        <button type='button'>{result.result4[[4]]}</button>
                                        <button type='button'>{result.result4[[5]]}</button>
                                        <button type='button'>{result.result4[[6]]}</button>
                                        <button type='button'>{result.result4[[7]]}</button>
                                    </div>
                                    <div className="winingPrice">500 DIME</div>
                                </div>
                            </div>
                        <br />
                        </>);
                    })}
                </div> 
            </div>
            <Footer/>
        </div>
     );
     else return (<></>);
}

export default KqMax;