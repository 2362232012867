import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const result = createSlice({
    name: 'result',
    initialState,
    reducers: {
        updateResult:   (state, action) => (action.payload)
    }
});

export const { updateResult } = result.actions;

export default result.reducer;