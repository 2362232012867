import React from 'react';
import { useState, useEffect } from 'react';

import Header from '../components/Lot6x45/Header';
import Footer from '../components/Footer';
import {userAccount, sendTransaction, updateJackpotLott6x45} from '../web3/testnet';

import UserBettingList from '../components/Lot6x45/UserBettingList';

import {socket} from '../services/lot6x45';

function amountControl (value) {
    // the first need to floor the number to make sure when we Float.toFixed it, the number is not bigger than balance.
    let temp = value * Math.pow(10, process.env.REACT_APP_TOKENPRECISION);
    temp = Math.floor(temp);
    temp = temp / Math.pow(10, process.env.REACT_APP_TOKENPRECISION);
    
    // now return the quantity in correct format of token precision and symbol.
    return Number.parseFloat(temp).toFixed(process.env.REACT_APP_TOKENPRECISION) + ' ' + process.env.REACT_APP_TOKENSYMBOL;
  }

function Lot6x45() {
    const numStart = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0
    };

    const [ num, setNum ] = useState(numStart);

    const [location, setLocation] = useState(1);

    const cNameOriginal = {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: ""
    };
    const [cName, setCName] = useState(cNameOriginal);

    function setPosition (value) {
        //console.log(num);
        let temp = cNameOriginal;
        temp[value] = "opacity5";
        setLocation(value);
        setCName(temp);
    }

    function handleNumber(e) {
        console.log(e.target.value);
        //let temp = num;
        let newPos = {};
        newPos[location] = e.target.value;
        setNum({...num, ...newPos});
        //console.log(num);
    } 

    function buyTicket () {
        const transact = {
            actions: [{
                account: process.env.REACT_APP_TOKENCONTRACT, 
                name: 'transfer',
                authorization: [{
                actor: userAccount,
                permission: 'active',
                }],
                data: {
                from: userAccount,
                to: process.env.REACT_APP_LOT,
                quantity:  amountControl(5),
                memo: "play" + ';' + num[1] + ';' + num[2] + ';' + num[3] + ';' + num[4] + ';' + num[5] + ';' + num[6],
                },
            }]
        };
        console.log(transact);
        sendTransaction(transact);
    }

    const [jackpot, setJackpot] = useState("START from 60000 DIME");

    useEffect(() => {
        if (jackpot === "START from 60000 DIME")
        updateJackpotLott6x45 (process.env.REACT_APP_LOT, (result) => {
            if (result.balance) setJackpot(result.balance);
            console.log("Jackpot: ", result.balance)
        });

        socket.on('TOTAL_BET', (...args) => {
            setJackpot(args[0].jackpot);
            console.log(jackpot);
        });
        return () => {
            socket.off('TOTAL_BET');
        };
    });

    return ( 
        <div className="PageWraper LotterypageWrap lotteryBtn  pt-5">
            <Header/>
            <div className="tablet-btn text-center">
                <a className="BlueBtn">Connect to wallet</a>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="LotterypageNumber LotterypageNumber2 blueCard">
                            <div className="timeCountDown">
                                <p>8<span>h</span></p>:<p>15<span>m</span></p>:<p>30<span>s</span></p>
                            </div>
                            <div className="selectNumberLott selectNumberLottBtns">
                                <div className="selectNumberLottBtnsList">
                                    <button type='button' onClick={() => setPosition(1)} className={cName[1]} >{num[1]}</button>
                                    <button type='button' onClick={() => setPosition(2)} className={cName[2]} >{num[2]}</button>
                                    <button type='button' onClick={() => setPosition(3)} className={cName[3]} >{num[3]}</button>
                                    <button type='button' onClick={() => setPosition(4)} className={cName[4]} >{num[4]}</button>
                                    <button type='button' onClick={() => setPosition(5)} className={cName[5]} >{num[5]}</button>
                                    <button type='button' onClick={() => setPosition(6)} className={cName[6]} >{num[6]}</button>
                                </div>
                                <p>Selected number for the lottery</p>
                                <h4><span className='color-yellow'>JACKPOT PRICE </span> {jackpot}</h4>
                            </div>
                            <div className="numberRange">
                                <div className="rangeInput">
                                    <input type="range" min="1" max="45"  onChange={
                                        (e) => {
                                            let newPos = {};
                                            newPos[location] = e.target.value;

                                            // check duplicate before set new value.
                                            let duplicate = false;
                                            const list = Object.values(num);
                                            list.forEach (element => {
                                                if (e.target.value === element) duplicate = true;
                                            });

                                            if (!duplicate) setNum({...num, ...newPos});
                                        } 
                                    } value={num[location]} />
                                </div>
                                <div className="rangeNumber">
                                    <p>00</p>
                                    <p>45</p>
                                </div>
                            </div>
                        </div>
                        <div className="lotteryBeat">
                            <div className="buy_ticket_box">
                                <p> Pay <span className='color-yellow'>5 DIME</span> Game Price and buy ticket</p>
                                <button onClick={() => buyTicket()} type='button' className="buyTicketsBtn">
                                    Buy Ticket
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <UserBettingList/>
                    </div>
                </div>
                <div className="how_to_playBox">
                    <h2>How to Play : </h2>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="howToPlayCard">
                                <h3>Buy Tickets :</h3>
                                <p>Buy a ticket and Choose numbers for the ticket.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="howToPlayCard">
                                <h3>Wait for the Draw :</h3>
                                <p>Wait for the draw at 15:00 UTC+0 every day.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="howToPlayCard">
                                <h3>Check the Prizes :</h3>
                                <p>Once the draw is over, Come back to this page and check your prize.</p>
                            </div>
                        </div>
                    </div>
                    <p className='color-blue'>For each draw you have to select the number for betting, then wait for the draw and get chance to win the prize. </p>
                    <h5 className='color-yellow'>1 win 99x for DE <span className='color-blue'>and</span> 27 win 99x (1 win 99/27) for LO</h5>
                </div>
            </div>
            <Footer/>
        </div>
     );
}

export default Lot6x45;

/*
<button type='button' onClick={() => setPosition(3)}>{num3}</button>
<button type='button' onClick={() => setPosition(4)}>{num4}</button>
<button type='button' onClick={() => setPosition(5)} className='opacity5'>{num5}</button>
                                    
*/