import React from 'react';

function BetStart() {
    return ( 
        <div className="gameWill">
            <h2 className='waitFor'>Wait for result</h2>
        </div>
     );
}

export default BetStart;