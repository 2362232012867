import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

import Header from '../components/Lode/Header';
import Footer from '../components/Footer';
import Star from '../assets/img/new/star.svg';

import { resultLoDe } from '../web3/testnet';

function KqLode() {

    const ResultState = useSelector((store) => store.result);
    const [round, setRound] = useState(0);

    useEffect(() => {
        resultLoDe(process.env.REACT_APP_LODE);
        return () => {
        };
    }, []);

    function nhay (valueArray, number) {
        let mp = new Map();
        for (let i = 0; i < valueArray.length; i++)
        {
            if(mp.has(valueArray[i]))
                mp.set(valueArray[i], mp.get(valueArray[i])+1)
            else
                mp.set(valueArray[i], 1)
        }
        return mp.get(number);
    }

    const onetohundred = Array(100).fill(0).map((n, i) => n + i);
    function chooseDay (e) {
        console.log(e.target.value);
        setRound(e.target.value);
    }

    if (ResultState.type === "lode")
    return ( 
        <div className="PageWraper resultPage ResultPageWrape lotteryBtn pt-5">
            <Header/>
            <div className="tablet-btn text-center">
                <a className="BlueBtn affiliaeBtn"><img src="/static/media/icons/Affilate.svg" alt="Walet" /> 1520</a>
            </div>
            <div className="container">
                <div className="resultContainer">
                    <div className="resultTop">
                        <p className='starNumber'><img src={Star} alt="Star" /> <span>{ResultState.data[round].lo_result[0]}</span> is the star number</p>
                        <div className="select_box">
                            <select onChange={(e) => chooseDay(e)}>
                                {ResultState.data.map( (day, key) => {
                                    return <option selected={key == round} key={v4()} value={key}>{day.ended_at}</option>
                                })}
                            </select>
                        </div>
                    </div>    
                    <div className="result_number">
                    {
                        onetohundred.map((key, value) => {
                            return <>
                                {ResultState.data[round].lo_result.includes(key) ? 
                                <>{
                                    key == ResultState.data[round].lo_result[0] ? 
                                        <button key={v4()} type='button' className="resultNumberItem activeWiner">{key}</button> : 
                                        
                                        <button key={v4()} type='button' className="resultNumberItem active">{key}{nhay(ResultState.data[round].lo_result,key) > 1 ? <span>{nhay(ResultState.data[round].lo_result,key)}</span> : <></>}</button>
                                    }</> 
                                : <button key={v4()} type='button' className="resultNumberItem">{key}</button>}
                            </>
                        })}
                    </div>
                </div> 
            </div>
            <Footer/>
        </div>
     );
    else return (<></>);
}

export default KqLode;